#controlBoxDiv {
	display: flex;
	justify-content: space-around;

	border-bottom: 3px solid #4c3285;

	padding-bottom: 15px;
	margin: 20px 0px;
}

#controlBoxDiv > #searchAndButtonsDiv {
	flex: 1;
	padding: 15px 10px;
}

#controlButtonsDiv {
	display: flex;
	justify-content: space-between;
	position: relative;
	margin-top: 25px;
}

.buttonDark {
	background-image: linear-gradient(to bottom, black, #4c3285);
	padding: 10px;
	border-radius: 1.5em;
	width: 48%;
	text-align: center;
}

.buttonDark:hover {
	color: #cfc4e9;
	cursor: pointer;
}

#purpleBackground {
	background-color: #4c3285;
	color: white;
}

.ui.modal > .actions {
	display: flex;
	align-items: center;
	justify-content: center;
}

#optionsModal > .content {
	color: black;
	padding: 20px;
}

#optionsModalDiv > div {
	margin-top: 10px;
}

#playlistModal {
	color: black;
}

#summaryHead {
	margin: 10px 0px;
}

#playlistModalWarning {
	color: red;
	text-align: center;
	font-weight: bold;
}

#playlistCreatedModal {
	color: black;
}

#playlistCreatedModal a {
	color: #4c3285;
	text-decoration: underline;
	word-wrap: break-word;
}

@media (max-width: 576px) {
	#optionsModalDiv {
		display: flex;
		justify-content: left;
		flex-direction: column;
	}
}

@media (min-width: 577px) {
	#optionsModalDiv {
		display: flex;
		justify-content: left;
		flex-direction: row;
	}

	#optionsModalDiv > div:last-child {
		margin-left: 10px;
	}
}
