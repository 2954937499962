#footer-links {
	display: flex;
	justify-content: space-evenly;
	margin: 20px auto;
}

#footer-links a {
	text-decoration: none;
}

#footer {
	text-align: center;
	padding-top: 10px;
}

i.icon.coffee:before {
	margin-left: -0.1em;
}
