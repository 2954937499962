.artistDiv {
	color: black;
	border-radius: 50px;
	padding: 10px;
	background-color: white;
	margin-top: 5px;
	margin-bottom: 5px;
	margin-right: 15px;
	display: flex;
	height: 50px;
	align-items: center;
	justify-content: space-evenly;
}

.artistDiv > .artistName {
	margin-left: 10px;
	margin-right: 5px;
}

.artistDiv > img {
	height: 100%;
	object-fit: contain;
	margin-left: 10px;
}

.artistDiv .button {
	margin-left: 10px;
}

#noArtistDiv {
	color: #cfc4e9;
}

.normalPointer:hover {
	cursor: default;
}

#recArtistListDiv {
	padding: 10px 0px;
}

.greyName {
	color: #919191;
}

@media (max-width: 576px) {
	.artistListDiv {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		flex-wrap: wrap;
		justify-content: center;
	}
}

@media (min-width: 577px) {
	.artistListDiv {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		flex-wrap: wrap;
	}
}
