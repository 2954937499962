#customTable {
	width: 95%;
	margin: 0 auto;
}

#customTable * {
	background-color: black;
	color: white;
	border: 0em;
	text-decoration: none;
	white-space: pre-wrap; /* CSS3 */
	white-space: -moz-pre-wrap; /* Firefox */
	white-space: -pre-wrap; /* Opera <7 */
	white-space: -o-pre-wrap; /* Opera 7 */
	word-wrap: normal; /* IE */
}

#customTable > thead > tr > th {
	color: #a6a6a6;
	border-bottom: 0.05em solid #a6a6a6;
}

#customTable > tbody > tr > td > a:hover {
	text-decoration: underline;
	cursor: pointer;
}

#customTable.ui.table {
	border: 0em;
}

.defaultTableDiv {
	text-align: center;
	color: #a6a6a6;
	padding: 3em 0em;
}

#popButton {
	box-shadow: none !important;
	padding: 5px;
}
